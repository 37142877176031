import React from 'react';
import { TaskStatusType } from '@store/tasks/contracts';
import { statusTaskColors } from '@components/ui/StatusLabel/StatusLabel';
import styles from './TaskStatusList.module.scss';

type LocalizedStatus = {
  name: string;
  statusType: TaskStatusType;
};

export const localisedTaskStatuses: LocalizedStatus[] = [
  {
    name: 'Начальный',
    statusType: TaskStatusType.New,
  },
  {
    name: 'В процессе',
    statusType: TaskStatusType.InProgress,
  },
  {
    name: 'На паузе',
    statusType: TaskStatusType.Paused,
  },
  {
    name: 'Отменен',
    statusType: TaskStatusType.Cancelled,
  },
  {
    name: 'Завершен',
    statusType: TaskStatusType.Done,
  },
];

export const TaskStatusList: React.FC = () => {
  return (
    <div className={styles.status__list}>
      <ul>
        {Object.entries(localisedTaskStatuses).map(([_, value]) => (
          <li className={styles.statusName} key={value.name}>
            <div
              style={{ backgroundColor: statusTaskColors.get(value.statusType) }}
              className={styles.status__color}></div>
            {value.name}
          </li>
        ))}
      </ul>
    </div>
  );
};
