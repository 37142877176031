import { Paper } from '@mui/material';
import { ProjectBars } from './ProjectBars';
import { TaskStatusList } from './TaskStatusList';
import styles from './TasksByStatus.module.scss';
import { useGetTaskStatusesQuery } from '@store/dashboard/dashboard.slice';

import { useFormikContext } from 'formik';

import { DashboardAnalyticsProjectsFormikContext } from '@pages/dashboard/DashboardAnalyticsProjects';

export const TasksByStatus = () => {
  const { values } = useFormikContext<DashboardAnalyticsProjectsFormikContext>();

  const { data: projects } = useGetTaskStatusesQuery({ projectIds: values.selectedProject || [] });

  return (
    <Paper elevation={0} variant="outlined" className={styles.container}>
      <h2 className={styles.title}>Количество задач по статусам</h2>
      <p className={styles.select__label}>Проекты</p>
      <div className={styles.content}>
        <ProjectBars projects={projects} />
        <TaskStatusList />
      </div>
    </Paper>
  );
};
