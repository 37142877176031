import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import {
  ChangePasswordRequest,
  FavoriteUsersResponse,
  IdentifierType,
  INTEGRATION_PROVIDER,
  OAUTH_REDIRECT,
  OauthProvider,
  ProfileAboutCompanyRequest,
  ProfileAboutCompanyResponse,
  ProfileMeResponse,
  ProfilesRequestBody,
  ResetPasswordRequest,
  ResetPasswordUpdateRequest,
  Role,
  SignInRequest,
  SignInResponse,
  SignOutRequest,
  SignOutResponse,
  SignUpRequest,
  SignUpResponse,
  User,
  UserQueryResponse,
  UserResponse,
} from '@store/user/contracts';
import * as UserService from '@store/user/user.service';
import * as IntegrationService from '@store/user/integration.service';
import { api, apiErrorHandler, setUserLogout } from '@store/api-client';
import { AppAbility, defineAbilityFor } from './ability';
import Api from '@api-schema';
import { enqueueSnackbar } from 'notistack';
import { PathParameters, RequestBody, ResponseBody } from '@store/utility';
import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { plainToInstance } from 'class-transformer';
import { SelectOption } from '@components/MUI/Select';

export const TOKEN_KEY = 'easy.access_token';

const { build } = new AbilityBuilder<AppAbility>(createMongoAbility);

const initialState: UserState = {
  user: null,
  token: null,
  ability: build(),
  providers: null,
};

type UserState = {
  user: ProfileMeResponse | null;
  ability: AppAbility;
  token: SignInResponse | null;
  providers: MyOauthProvider[] | null;
};

type SetAuthTokenActionPayload = {
  token: SignInResponse | null;
};

type SetUserProfileActionPayload = {
  user: ProfileMeResponse;
};

type MyOauthProvider = OauthProvider | undefined;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<SetAuthTokenActionPayload>) => {
      state.token = action.payload.token;
    },
    setUserProfile: (state, action: PayloadAction<SetUserProfileActionPayload>) => {
      state.user = action.payload.user;
      state.ability = defineAbilityFor(action.payload.user.roles);
    },
    setProviders: (state, action: PayloadAction<MyOauthProvider[]>) => {
      state.providers = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.ability = build();
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(userApi.endpoints.login.matchFulfilled, (state, { payload }) => {
        state.token = payload;
        if (payload !== undefined && payload !== null)
          localStorage.setItem(TOKEN_KEY, JSON.stringify(payload));
      })
      .addMatcher(userApi.endpoints.logout.matchFulfilled, (state) => {
        state.user = null;
        state.token = null;
        state.ability = build();
      })
      .addMatcher(userApi.endpoints.me.matchFulfilled, (state, { payload }) => {
        state.user = payload;
        state.ability = defineAbilityFor(payload.roles);
      });
  },
});

export const userLogout = createAsyncThunk('user/logout', async (_, { dispatch }) => {
  localStorage.removeItem(TOKEN_KEY);

  /*
                                              const token = (getState() as RootState).user?.token!;
                                              if(token)
                                              {
                                              await UserService.logout({
                                                token.accessToken,
                                              });
                                              }
                                            */
  dispatch(logout());
});
setUserLogout(userLogout);

const loadProfile = createAsyncThunk('user/profile', async (_, { dispatch, getState }) => {
  try {
    const user = await UserService.me();
    const token = (getState() as RootState).user?.token!;
    dispatch(setToken({ token }));
    dispatch(setUserProfile({ user }));
  } catch {
    dispatch(setToken({ token: null }));
  }
});

export const loadProviders = createAsyncThunk('user/providers', async (_, { dispatch }) => {
  try {
    const vkProvider = await UserService.getOauthProviders(OAUTH_REDIRECT.VK_REDIRECT);
    const yandexProvider = await UserService.getOauthProviders(OAUTH_REDIRECT.YANDEX_REDIRECT);
    const providers = [
      vkProvider.find((provider) => provider.name === 'VK'),
      yandexProvider.find((provider) => provider.name === 'YANDEX'),
    ];
    await dispatch(setProviders(providers));
  } catch (error) {
    apiErrorHandler(error);
  }
});

export const connectToIntegrationProvider = createAsyncThunk(
  'user/integration/connect',
  async (provider: INTEGRATION_PROVIDER) => {
    window.location.href = await IntegrationService.resolveConnectLinkToIntegrationProvider(
      provider,
      document.URL.toString(),
    );
  },
);

export const disconnectIntegrationProvider = createAsyncThunk(
  `user/integration/disconnect`,
  async (provider: INTEGRATION_PROVIDER, { dispatch }) => {
    await IntegrationService.disconnectIntegrationProvider(provider);
    await dispatch(loadProfile());
    enqueueSnackbar(`Интеграция с ${provider} успешно отключена`, {
      variant: 'success',
    });
  },
);

const { setToken, setUserProfile, logout, setProviders } = userSlice.actions;

export const isAuthenticated = (state: RootState) => state.user.token !== null;
export const selectProfile = (state: RootState) => state.user.user;
export const selectCurrentUserId = (state: RootState) => state.user.user?.id;

export const selectUserIntegrations = (state: RootState) => state.user.user?.integration ?? [];
export const isEnabledIntegration = (provider: INTEGRATION_PROVIDER) => (state: RootState) =>
  state.user.user?.integration?.includes(provider) ?? false;

export const selectAbility = (state: RootState) => state.user.ability;

export const selectShortNameUser = (state: RootState) => {
  const user = state.user.user;

  return user?.about?.firstName && user?.about?.lastName
    ? `${user.about.firstName[0]}${user.about.lastName[0]}`
    : null;
};

export const selectMyRolesForSelect = (state: RootState): SelectOption[] => {
  const roles = state.user.user?.roles.filter((role) => {
    return role.name !== Role.Admin && role.name !== Role.Default;
  });

  const rolesFromDictionary = state.dictionary['ROLE'];

  const rolesForSelect = rolesFromDictionary.filter((roleFromDictionary) =>
    roles?.find((role) => role.id === roleFromDictionary.id),
  );

  if (rolesForSelect) {
    return rolesForSelect.map((role) => ({
      value: role.code,
      label: role.name as string,
    }));
  }

  return [];
};

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createOrganizationConnectRequest: builder.mutation<
      ResponseBody<Api.operations['sendConnectRequest']>,
      RequestBody<Api.operations['sendConnectRequest']>
    >({
      query: (request) => ({
        url: '/api/education/connect',
        method: 'POST',
        body: request,
      }),
    }),

    registration: builder.mutation<SignUpResponse, SignUpRequest>({
      query: (requestBody) => ({
        url: '/api/user/registration',
        method: 'POST',
        body: requestBody,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          sessionStorage.setItem('email', arg.email);
          enqueueSnackbar('Успешно отправлено', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: [],
    }),

    confirmRegistration: builder.mutation<
      ResponseBody<Api.operations['registration_1']>,
      RequestBody<Api.operations['registration_1']>
    >({
      query: (requestBody) => ({
        url: `/api/user/registration/verify`,
        method: 'POST',
        body: requestBody,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar(`Регистрация прошла успешно`, {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['MyProfile'],
    }),

    login: builder.mutation<SignInResponse, SignInRequest>({
      query: (requestBody) => ({
        url: `/api/auth/token/sign-in`,
        method: 'POST',
        body: requestBody,
      }),
      invalidatesTags: ['MyProfile'],
    }),

    setFavoriteProfile: builder.mutation<
      ResponseBody<Api.operations['changeFavoriteStatus_4']>,
      {
        isFavorite: RequestBody<Api.operations['changeFavoriteStatus_4']>;
        profileId: PathParameters<Api.operations['changeFavoriteStatus_4'], 'id'>;
      }
    >({
      query: (requestBody) => ({
        url: `/api/profile/${requestBody.profileId}/favorite`,
        method: 'PUT',
        body: requestBody.isFavorite,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar(`Раздел "Избранное" успешно обновлен`, {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['Profiles', 'Profile', 'MyProfile', 'FavoriteProfiles'],
    }),

    getFavoriteProfiles: builder.query<
      FavoriteUsersResponse,
      RequestBody<Api.operations['getFavoriteProfiles']>
    >({
      query: (requestBody) => ({
        url: `/api/profile/favorite`,
        method: 'POST',
        body: requestBody,
      }),
      transformResponse: (response: ResponseBody<Api.operations['getFavoriteProfiles']>) => {
        return plainToInstance(FavoriteUsersResponse, response);
      },
      providesTags: ['FavoriteProfiles'],
    }),

    setProfile: builder.mutation<
      ResponseBody<Api.operations['updateProfile']>,
      RequestBody<Api.operations['updateProfile']>
    >({
      query: (requestBody) => ({
        url: `/api/profile/`,
        method: 'PATCH',
        body: requestBody,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar(`Информация о профиле успешно изменена`, {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['MyProfile'],
    }),

    setInfoAboutWork: builder.mutation<
      ResponseBody<Api.operations['saveProfileWork']>,
      RequestBody<Api.operations['saveProfileWork']>
    >({
      query: (requestBody) => ({
        url: '/api/profile/work',
        method: 'POST',
        body: requestBody,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar(`Ваши данные обновлены`, {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['MyProfile'],
    }),

    setInfoAboutCompany: builder.mutation<ProfileAboutCompanyResponse, ProfileAboutCompanyRequest>({
      query: (requestBody) => ({
        url: `api/profile/organization`,
        method: 'POST',
        body: requestBody,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar(`Информация о компании успешно обновлена`, {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['MyProfile'],
    }),

    getProfiles: builder.query<UserResponse, ProfilesRequestBody>({
      query: (requestBody) => ({
        url: 'api/profile/list',
        method: 'POST',
        body: requestBody,
      }),
      transformResponse: (response: ResponseBody<Api.operations['getProfiles']>) => {
        return plainToInstance(UserResponse, response);
      },
      providesTags: ['Profiles'],
    }),

    setInfoAboutLink: builder.mutation<
      ResponseBody<Api.operations['saveProfileLink']>,
      RequestBody<Api.operations['saveProfileLink']>
    >({
      query: (requestBody) => ({
        url: 'api/profile/link',
        method: 'POST',
        body: requestBody,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar(`Информация о контактах успешно обновлена`, {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['MyProfile'],
    }),

    logout: builder.mutation<SignOutResponse, SignOutRequest>({
      query: (requestBody) => ({
        url: `/api/auth/token/sign-out`,
        method: 'POST',
        body: requestBody,
      }),
      invalidatesTags: [
        'RequisiteList',
        'Requisite',
        'RequestDetail',
        'RequestList',
        'MyAssignedRequests',
        'ApprovedRequests',
        'RequestsCreatedByMe',
        'FavoriteRequests',
        'TeamsList',
        'TeamDetail',
        'FavoriteTeams',
        'TeamAvailablePositions',
        'EmployeesAnalytics',
        'ProjectDetail',
        'CustomerProjects',
        'ManagerProjects',
        'ManagerCustomerProjects',
        'ProjectList',
        'ExecutorProjects',
        'ProjectStatuses',
        'ProjectQueues',
        'ProjectTypes',
        'ProjectPriorities',
        'ProjectExecutors',
        'DashboardTimeAnalytics',
        'DashboardTaskStatuses',
        'DashboardProjectStatuses',
        'DashboardRequestAnalytics',
        'DashboardVacanciesAnalytics',
        'FavoriteDocs',
        'OffersList',
        'Vacancy',
        'VacancyResponses',
        'MyVacancies',
        'FavaoriteVacancies',
        'VacanciesExecutor',
        'MyOffers',
        'TasksList',
        'TaskDetail',
        'TaskTransitions',
        'FavoriteProfiles',
        'Profiles',
        'Profile',
        'MyProfile',
        'SearchProfiles',
        'OauthProviders',
        'OauthToken',
      ],
    }),

    getProfile: builder.query<User, PathParameters<Api.operations['getProfile'], 'id'>>({
      query: (id) => ({
        url: `/api/profile/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: ResponseBody<Api.operations['getProfile']>) => {
        return plainToInstance(User, response);
      },
      providesTags: ['Profile'],
    }),

    me: builder.query<ProfileMeResponse, any>({
      query: () => ({
        url: 'api/profile/me',
        method: 'GET',
      }),
      transformResponse: (response: ResponseBody<Api.operations['getProfileMe']>) => {
        return plainToInstance(ProfileMeResponse, response);
      },
      providesTags: ['MyProfile'],
      keepUnusedDataFor: 0,
    }),

    searchProfiles: builder.query<
      UserQueryResponse,
      Api.operations['searchUsers']['parameters']['query']
    >({
      query: (params) => ({
        url: 'api/profile/search',
        method: 'GET',
        params: params,
      }),
      transformResponse: (response: ResponseBody<Api.operations['searchUsers']>) => {
        return plainToInstance(UserQueryResponse, response);
      },
      providesTags: ['SearchProfiles'],
    }),

    uploadProfilePhoto: builder.mutation<
      ResponseBody<Api.operations['uploadFile']>,
      { formData: FormData; tag: string }
    >({
      query: ({ formData, tag }) => ({
        url: `/api/file/${tag}/upload`,
        method: 'POST',
        body: formData,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar(`Аватар успешно загружен`, {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['MyProfile'],
    }),

    resetPasswordRequest: builder.mutation<
      ResponseBody<Api.operations['sendVerificationCodeForPasswordChanging']>,
      ResetPasswordRequest
    >({
      query: (requestBody) => ({
        url: `/api/user/reset-password`,
        method: 'POST',
        body: { identifierTypeId: IdentifierType.Email, identifierValue: requestBody.email },
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Успешно отправлено на почту', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: [],
    }),

    resetPasswordUpdate: builder.mutation<
      ResponseBody<Api.operations['sendVerificationCodeForPasswordChanging']>,
      ResetPasswordUpdateRequest
    >({
      query: (requestBody) => ({
        url: `/api/user/reset-password`,
        method: 'PUT',
        body: {
          identifierTypeId: IdentifierType.Email,
          identifierValue: requestBody.email,
          verificationCode: requestBody.code,
          newPassword: requestBody.newPassword,
        },
      }),
      invalidatesTags: [],
    }),

    changePassword: builder.mutation<
      ResponseBody<Api.operations['changePassword_1']>,
      ChangePasswordRequest
    >({
      query: (requestBody) => ({
        url: `/api/user/change-password`,
        method: 'POST',
        body: requestBody,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Пароль успешно обновлен', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: [],
    }),

    removeProfileIcon: builder.mutation<ResponseBody<Api.operations['deleteIcon']>, any>({
      query: () => ({
        url: `/api/profile/icon`,
        method: 'DELETE',
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Аватар удален', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['MyProfile'],
    }),

    getOauthProviders: builder.query<
      ResponseBody<Api.operations['providers']>,
      Api.operations['providers']['parameters']['query']['redirect']
    >({
      query: (redirect) => ({
        url: `/platform/oauth/providers`,
        method: 'GET',
        params: { redirect: redirect },
      }),
      providesTags: ['OauthProviders'],
    }),

    getOauthToken: builder.query<
      ResponseBody<Api.operations['getTokenWithOauth']>,
      Api.operations['getTokenWithOauth']['parameters']
    >({
      query: ({ path, query }) => ({
        url: `/platform/oauth/${path.authProvider}`,
        method: 'GET',
        params: { redirect: query.redirect, code: query.code },
      }),
      providesTags: ['OauthToken'],
    }),

    deleteAccount: builder.mutation<
      ResponseBody<Api.operations['sendSupportMessage']>,
      RequestBody<Api.operations['sendSupportMessage']>
    >({
      query: (requestBody) => ({
        url: '/api/support/send',
        method: 'POST',
        body: requestBody,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Запрос на удаление аккаунта отправлен', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: [],
    }),

    redirectToYandexIntegration: builder.query<string, string>({
      query: (redirect) => ({
        url: `api/integration/yandex/connect`,
        method: 'GET',
        params: { redirect: redirect },
        maxRetries: 0,
        headers: { Accept: 'text/plain' },
      }),
    }),

    disconnectYandexIntegration: builder.mutation<any, any>({
      query: () => ({
        url: `api/integration/yandex/disconnect`,
        method: 'POST',
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar(`Интеграция с Яндекс успешно отключена`, {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['MyProfile'],
    }),
  }),
});

export const {
  useCreateOrganizationConnectRequestMutation,
  useRegistrationMutation,
  useConfirmRegistrationMutation,
  useLoginMutation,
  useSetFavoriteProfileMutation,
  useGetFavoriteProfilesQuery,
  useSetProfileMutation,
  useSetInfoAboutWorkMutation,
  useMeQuery,
  useSetInfoAboutCompanyMutation,
  useSetInfoAboutLinkMutation,
  useLazyGetProfileQuery,
  useDeleteAccountMutation,
  useGetOauthProvidersQuery,
  useLazyGetOauthTokenQuery,
  useUploadProfilePhotoMutation,
  useRemoveProfileIconMutation,
  useChangePasswordMutation,
  useResetPasswordRequestMutation,
  useResetPasswordUpdateMutation,
} = userApi;

export const userReducer = userSlice.reducer;
