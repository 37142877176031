import { useFormikContext } from 'formik';
import styles from './DashboardAnalyticsProjectsSelectorWrapper.module.scss';
import { FormikObserver } from '@helpers/FormikObserver';
import { ReactNode, useCallback, useMemo } from 'react';
import { Select, SelectOption } from '@components/MUI/Select';
import { RoleCodes } from '@store/user/contracts';
import { useGetProjectsQuery } from '@store/project/project.slice';
import { Checkbox, InputAdornment, ListItemText, MenuItem } from '@mui/material';
import { ReactComponent as ClearIcon } from '@assets/img/close-icon.svg';
import { DashboardAnalyticsProjectsFormikContext } from '@pages/dashboard/DashboardAnalyticsProjects';

type DashboardAnalyticsProjectsSelectorWrapperProps = {
  children: ReactNode;
};

export const DashboardAnalyticsProjectsSelectorWrapper = ({
  children,
}: DashboardAnalyticsProjectsSelectorWrapperProps) => {
  const { values, setFieldValue } = useFormikContext<DashboardAnalyticsProjectsFormikContext>();
  const { data: usersProjects } = useGetProjectsQuery({
    limit: 50,
    start: 0,
    sortOrder: 'desc',
    roles: [RoleCodes.Customer, RoleCodes.Manager],
  });

  const projectForSelect = useMemo(() => {
    return usersProjects?.data.map((usersProject) => {
      return {
        value: usersProject.id,
        label: usersProject.name,
      };
    });
  }, [usersProjects?.data]);

  const convertValuesToLabels = useCallback(
    (selectedValues: string[]) => {
      return selectedValues.map((value) => {
        return projectForSelect?.find((projectToSelect) => projectToSelect.value === value)?.label;
      });
    },
    [projectForSelect],
  );

  const renderOption = useCallback((option: SelectOption, value: string[]) => {
    return (
      <MenuItem value={option.value} key={option.value}>
        <div className={styles.select_option}>
          <Checkbox
            sx={{
              padding: '0',
            }}
            checked={value.includes(option.value)}
          />
          <ListItemText primary={option.label} />
        </div>
      </MenuItem>
    );
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.select}>
        <FormikObserver name="selectedProject" />
        <Select
          name="selectedProject"
          values={projectForSelect ?? []}
          isOutlined
          fullWidth
          disabled={projectForSelect?.length === 0}
          multiple
          displayEmpty
          renderValue={(selected) => {
            if ((Array.isArray(selected) && selected?.length === 0) || !selected) {
              return <p className={styles.select_placeholder}>Выберите проект</p>;
            } else if (Array.isArray(selected)) {
              const selectedLabels = convertValuesToLabels(selected);
              return <p className={styles.select_selectedProjects}>{selectedLabels.join(', ')}</p>;
            }
          }}
          renderOption={renderOption}
          endAdornment={
            values.selectedProject.length > 0 ? (
              <InputAdornment position="end" sx={{ marginRight: '16px' }}>
                {values.selectedProject.length > 1 && (
                  <span className={styles.select_selectedCounter}>
                    {values.selectedProject.length}
                  </span>
                )}
                <ClearIcon
                  className={styles.select_clearIcon}
                  onClick={() => setFieldValue('selectedProject', [])}
                />
              </InputAdornment>
            ) : (
              <></>
            )
          }
        />
      </div>
      {children}
    </div>
  );
};
