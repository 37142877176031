import React from 'react';
import { AmountOfProjects } from './Projects/AmountOfProjects/AmountOfProjects';
import { TimeExpensesAnalytics } from './Projects/TimeExpensesAnalytics/TimeExpensesAnalytics';
import { TasksByStatus } from './Projects/TasksByStatus/TasksByStatus';
import styles from './DashboardAnalyticsProjects.module.scss';
import { Formik } from 'formik';
import { DashboardAnalyticsProjectsSelectorWrapper } from './Projects/DashboardAnalyticsProjectsSelector';

export interface DashboardAnalyticsProjectsFormikContext {
  selectedProject: string[];
}

export const DashboardAnalyticsProjects: React.FC = () => {
  return (
    <>
      <Formik
        onSubmit={() => {}}
        initialValues={{
          selectedProject: [],
        }}>
        {() => (
          <DashboardAnalyticsProjectsSelectorWrapper>
            <div className={styles.projects__page}>
              <AmountOfProjects />
              <TimeExpensesAnalytics />
              <TasksByStatus />
            </div>
          </DashboardAnalyticsProjectsSelectorWrapper>
        )}
      </Formik>
    </>
  );
};
