import { useState } from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import downArrow from '@assets/img/header/down-arrow-white.svg';
import burgerMenu from '@assets/img/burger-menu.svg';
import exit from '@assets/img/exit_green.svg';
import logo from '@assets/img/logo.svg';
// import bell from '@assets/img/bell.svg';
import { selectShortNameUser, userLogout } from '@store/user/user.slice';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { toggleSidebar } from '@store/ui/ui.slice';
import { NavLink, useNavigate } from 'react-router-dom';
import { Menu } from '@components/MUI/Menu';
import styles from './Header.module.scss';
import { NotificationsList } from '@components/NotificationsList/NotificationsList';
import { ReactComponent as Profile } from '@assets/img/profile.svg';
import { ReactComponent as Balance } from '@assets/img/balance.svg';
import { ReactComponent as Favorite } from '@assets/img/favorite.svg';
import { Can } from '@access-control';

const Header = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  //  const [userLogout] = useLogoutMutation();
  //const token = useAppSelector(selectToken);

  const shortName = useAppSelector(selectShortNameUser);

  function toggleSidebarVisibility() {
    dispatch(toggleSidebar());
  }

  function logout() {
    dispatch(userLogout());
    //    userLogout({ token: token! });
    navigate('/');
  }

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <AppBar position="fixed" sx={{ paddingRight: '0 !important', boxShadow: 'none' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleSidebarVisibility}>
            <img src={burgerMenu} alt="Меню" />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img src={logo} alt="Логотип" />
          </Typography>
          {/* <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleNotificationMenu}>
            <img src={bell} alt="Уведомления" />
          </IconButton> */}
          <NotificationsList />
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
            sx={{
              width: '32px',
              height: '32px',
              borderRadius: '50%',
              backgroundColor: '#7fc11f',
              marginRight: 2,
              fontWeight: 'bold',
              fontSize: '16px',
              '&:hover': {
                backgroundColor: '#7fc11f',
              },
            }}>
            {shortName}
          </IconButton>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            aria-controls="menu-appbar"
            onClick={handleMenu}>
            <img
              src={downArrow}
              alt="стрелка вниз"
              style={{
                transform: `rotate(${anchorEl ? 180 : 0}deg)`,
                transition: 'transform 0.4s ease', // Добавляем плавность
              }}
            />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            slotProps={{
              paper: {
                sx: {
                  marginTop: '49px',
                  boxShadow: '1px 4px 12.7px 0px #00000040',
                  border: '1px solid #F0F0F0',
                  borderRadius: '3px',
                },
              },
            }}>
            <MenuItem className={styles.menu__button}>
              <NavLink to="/" className={({ isActive }) => (isActive ? '' : '')}>
                <Profile />
                <span className={styles.menu__item}>Профиль</span>
              </NavLink>
            </MenuItem>
            <Can I="create" a="Requisite">
              <MenuItem className={styles.menu__button}>
                <NavLink to="/balance" className={({ isActive }) => (isActive ? '' : '')}>
                  <Balance />
                  <span className={styles.menu__item}>Баланс</span>
                </NavLink>
              </MenuItem>
            </Can>
            <MenuItem className={styles.menu__button}>
              <NavLink to="/favorite" className={({ isActive }) => (isActive ? '' : '')}>
                <Favorite />
                <span className={styles.menu__item}>Избранное</span>
              </NavLink>
            </MenuItem>
            <MenuItem onClick={logout} className={styles.menu__button}>
              <img src={exit} alt="выход" />
              <span className={styles.menu__item}>Выйти</span>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export { Header };
