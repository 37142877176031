import { useFormikContext } from 'formik';
import { useEffect } from 'react';

type FormikObserverProps<T> =
  | {
      onChange: (values: T) => void;
      name?: never;
    }
  | {
      onChange?: never;
      name: keyof T;
    };

export const FormikObserver = <T,>({ onChange, name }: FormikObserverProps<T>) => {
  const { values, setFieldValue } = useFormikContext<T>();

  useEffect(() => {
    if (onChange) {
      onChange(values);
    } else {
      setFieldValue(name as string, values[name]);
    }
  }, [values, onChange]);

  return null;
};
